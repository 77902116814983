<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
      <h4 class="card-title">{{ $t('specialTaskForce.district_special_task_force_activities_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="division_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.division') }}
              </template>
              <b-form-select
                plain
                v-model="search.division_id"
                :options="divisionList"
                id="divistion_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="district_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.district') }}
              </template>
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
            >
            <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.from_date') }}
              </template>
              <date-picker
                id="start_date"
                class="form-control"
                v-model="search.start_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="date"
            >
              <template v-slot:label>
                {{ $t('globalTrans.to_date') }}
              </template>
              <date-picker
                id="end_date"
                class="form-control"
                v-model="search.end_date"
                :placeholder="$t('globalTrans.select')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" style="font-size: 14px;" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>
    <b-row>
      <b-col md="12" v-if="showData">
        <body-card>
          <!-- table section start -->
          <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('specialTaskForce.district_special_task_force_activities_report') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <a href="javascript:" class="btn-add" @click="pdfExport">
              {{ $t('globalTrans.print') }}
            </a>
            <!-- <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a> -->
          </template>
          <template v-slot:body>
            <b-overlay :show="load">
              <b-row>
                <b-col>
                  <list-report-head :base-url="bazarMonitoringServiceBaseUrl" uri="/configuration/report-head/detail" :org-id="1">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    <u>{{ $t('specialTaskForce.district_special_task_force_activities_report') }}</u>
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row class="main-search">
                <!-- <b-col md="4" class="main-searchs" style="color: green !important;">
                  {{ $t('specialTaskForce.task_force_number_of_agents') }}: <strong>{{ $n(numberAgent, { useGrouping: false }) }}</strong>
                </b-col>
                <b-col md="4" class="main-searchs" style="color: green !important">
                  {{ $t('specialTaskForce.legal_action') }} {{ $t('specialTaskForce.total_count') }}: <strong>{{ $n(personalTotalOrg, { useGrouping: false }) }}</strong>
                </b-col>
                <b-col md="4" class="main-searchs" style="color: green !important">
                  {{ $t('specialTaskForce.legal_action') }} {{ $t('specialTaskForce.penalty_imposed_total') }}: <strong>{{ $n(penalty, { useGrouping: false }) }}</strong>
                </b-col> -->
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.division') }}: <strong>{{ searchHeader.division_id ? getColumnName($store.state.CommonService.commonObj.divisionList, searchHeader.division_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.district') }}: <strong>{{ searchHeader.district_id ? getColumnName($store.state.CommonService.commonObj.districtList, searchHeader.district_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.from_date') }}:
                  <strong>
                    <slot v-if="searchHeader.start_date">
                      {{searchHeader.start_date | dateFormat}}
                    </slot>
                    <slot v-else>
                      {{$t('globalTrans.all')}}
                    </slot>
                  </strong>
                </b-col>
                <b-col md="4" class="main-search">
                  {{ $t('globalTrans.to_date') }}:
                  <strong>
                    <slot v-if="searchHeader.end_date">
                      {{searchHeader.end_date | dateFormat}}
                    </slot>
                    <slot v-else>
                      {{$t('globalTrans.all')}}
                    </slot>
                  </strong>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="table-responsive1">
                  <b-table-simple class="special-task tg mt-3" sticky-header bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                    <b-thead class="bg-primary">
                      <b-tr>
                        <b-th rowspan="2" style="width:7%" class="text-center bg-primary">{{ $t('globalTrans.sl_no') }}</b-th>
                        <b-th rowspan="2" style="width:13%" class="text-center bg-primary">{{ $t('globalTrans.division') }}</b-th>
                        <b-th rowspan="2" style="width:12%" class="text-center bg-primary">{{ $t('globalTrans.district') }}</b-th>
                        <b-th rowspan="2" style="width:12%" class="text-center bg-primary">{{ $t('specialTaskForce.task_force_number_of_agents') }}</b-th>
                        <b-th rowspan="2" style="width:12%" class="text-center bg-primary">{{ $t('specialTaskForce.task_force_activities_area') }}</b-th>
                        <b-th colspan="2" style="width:12%; top: 1px" class="text-center bg-primary position-sticky">{{ $t('specialTaskForce.legal_action') }}</b-th>
                        <b-th rowspan="2" style="width:12%" class="text-center bg-primary">{{ $t('specialTaskForce.remarks_recommendation') }}</b-th>
                        <b-th rowspan="2" style="width:12%" class="text-center bg-primary">{{ $t('globalTrans.description') }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:12%" class="text-center bg-primary position-top-31">{{ $t('specialTaskForce.total_count') }}</b-th>
                        <b-th style="width:12%" class="text-center bg-primary position-top-31">{{ $t('specialTaskForce.penalty_imposed_total') }}</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <slot v-if="datas.length>0">
                        <template v-for="(division, divisionIndex) in datas">
                          <template v-for="(district, districtIndex) in division.district">
                            <b-tr v-for="(info, infoIndex) in district.list" :key="divisionIndex + '_' + districtIndex + '_' + infoIndex + '_' + info.id">
                              <b-td class="text-center"  style="vertical-align: top;">{{ $n(info.serial, { useGrouping: false }) }}</b-td>
                              <slot v-if="districtIndex === 0 && infoIndex === 0">
                                <b-td :rowspan="division.rowSpan" class="text-center" style="vertical-align: top;">{{ getColumnName($store.state.CommonService.commonObj.divisionList, division.division_id)}}</b-td>
                              </slot>
                              <slot v-if="infoIndex == 0">
                                <b-td class="text-center" :rowspan="district.list.length" style="vertical-align: top;">{{ getColumnName($store.state.CommonService.commonObj.districtList, district.district_id)}}</b-td>
                              </slot>
                              <b-td class="text-right" style="vertical-align: top;">{{ $n(info.number_of_agents, { useGrouping: false }) }}</b-td>
                              <b-td class="text-left" style="vertical-align: top;">
                                <b-button style="color: black !important;background-color: transparent !important;border: 0px !important;" v-b-tooltip.hover :title="info.task_force_activities_area">
                                  <span v-html="truncatedText(info.task_force_activities_area)"></span>
                                </b-button>
                              </b-td>
                              <b-td class="text-right" style="vertical-align: top;">{{ $n(info.total, { useGrouping: false }) }}</b-td>
                              <b-td class="text-right" style="vertical-align: top;">{{ $n(info.penalty_fine ? info.penalty_fine_count : 0, { useGrouping: false }) }}</b-td>
                              <b-td class="text-left" style="vertical-align: top;">
                                <b-button style="color: black !important;background-color: transparent !important;border: 0px !important;" v-b-tooltip.hover1 :title="info.remarks">
                                  <span v-html="truncatedText(info.remarks)"></span>
                                </b-button>
                              </b-td>
                              <b-td class="text-center" style="vertical-align: top;"><b-button class="btn action-btn status mr-1 btn-sm" :title="$t('globalTrans.details')" v-b-modal.details variant=" iq-bg-success mr-1" size="sm" @click="edit(info)"><i class="ri-eye-line m-0 "></i></b-button></b-td>
                            </b-tr>
                          </template>
                        </template>
                        <b-tr>
                          <b-td class="text-right font-weight-bold" colspan="3">{{ $t('globalTrans.total') }}</b-td>
                          <b-td class="text-right font-weight-bold">{{ $n(numberAgent) }}</b-td>
                          <b-td></b-td>
                          <b-td class="text-right font-weight-bold">{{ $n(personalTotalOrg) }}</b-td>
                          <b-td class="text-right font-weight-bold">{{ $n(penalty) }}</b-td>
                          <b-td></b-td>
                          <b-td></b-td>
                        </b-tr>
                      </slot>
                      <slot v-else>
                          <b-tr>
                            <b-th colspan="9" class="text-center text-danger">
                              <br/>
                              {{ $t('globalTrans.noDataFound') }}
                              <br/>
                              <br/>
                            </b-th>
                          </b-tr>
                      </slot>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
          <!-- table section end -->
        </body-card>
      </b-col>
    </b-row>
    <b-modal id="details" size="lg" :title="$t('specialTaskForce.district_special_task_force_activities') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId" :data="data" :report="1"/>
    </b-modal>
  </div>
</template>
<script>
import Details from './Details.vue'
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { specialTaskForceDistrictReportList, specialTaskForceDistrictToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead,
    Details
  },
  data () {
    return {
      sortBy: '',
      search: {
        division_id: 0,
        district_id: 0,
        office_id: 0,
        start_date: '',
        end_date: ''
      },
      datas: [],
      searchHeader: {
        division_id: 0,
        district_id: 0,
        office_id: 0,
        start_date: '',
        end_date: ''
      },
      districtList: [],
      data: [],
      showData: false,
      bazarMonitoringServiceBaseUrl: bazarMonitoringServiceBaseUrl,
      load: false,
      numberAgent: 0,
      personalTotalOrg: 0,
      penalty: 0
    }
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal !== 0) {
          this.districtList = this.getDistrictList(newVal)
        }
      } else {
        this.districtList = []
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'inspection_date' },
          { key: 'number_of_agents' },
          { key: 'task_force_activities_area' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'inspection_date' },
          { key: 'number_of_agents' },
          { key: 'task_force_activities_area' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('specialTaskForce.district_special_task_force_activities') + ' ' + this.$t('globalTrans.entry') : this.$t('specialTaskForce.district_special_task_force_activities') + ' ' + this.$t('globalTrans.modify')
    },
    divisionList: function () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    }
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: this.orgId }, this.search)
      this.load = true
      const result = await RestApi.getPdfData(bazarMonitoringServiceBaseUrl, specialTaskForceDistrictReportList, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.load = false
    },
    edit (datas) {
      this.data = datas
    },
    getColumnName (list, groupId) {
        const obj = list.find(item => item.value === groupId)
        if (typeof obj !== 'undefined') {
            if (this.$i18n.locale === 'bn') {
                return obj.text_bn
            } else {
                return obj.text_en
            }
        } else {
            return ''
        }
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === parseInt(id))
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(3, bazarMonitoringServiceBaseUrl, specialTaskForceDistrictToggle, item)
      }
    },
    searchData () {
      this.loadData()
    },
    truncatedText (textIt) {
      if (textIt) {
        const words = textIt.split(' ')
        if (words.length > 5) {
          let seeMore = '<span style="color:blue"> ....See More </span>'
          if (this.$i18n.locale === 'bn') {
            seeMore = '<span style="color:blue"> ....আরো দেখুন </span>'
          }
          return words.slice(0, 5).join(' ') + seeMore
        }
        return textIt
      } else {
        return ''
      }
    },
    async loadData () {
      this.showData = true
      this.searchHeader = Object.assign({}, this.search)
      const params = Object.assign({}, this.search)
      this.load = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, specialTaskForceDistrictReportList, params)
      if (result.success) {
        this.load = false
        this.datas = []
        this.datas = result.data.data
        this.numberAgent = result.data.numberAgent
        this.penalty = result.data.penalty
        this.personalTotalOrg = result.data.personalTotalOrg
      } else {
        this.datas = []
        this.load = false
      }
    }
  }
}
</script>
<style scoped>
.special-task th {
  font-size: 17px !important;
  padding: 3px;
}
.special-task td {
  padding: 3px;
}
.sub_string{
  width: 150px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  cursor: pointer;
}
.main-search {
  font-size: 15px !important;
}
.main-searchs {
  font-size: 17px !important;
  font-weight: bold !important;
}
.b-table-sticky-header {
  overflow-y: auto !important;
  max-height: 800px !important;
}
.table thead {
  position: sticky;
  top: 0;
}
.position-top-31 {
  top: 31px !important;
}
</style>
